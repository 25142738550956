import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type InputProps = {
  disableFullWidth?: boolean;
  disableStyle?: boolean;
  hasBorder?: boolean;
  paddingX?: 'SM' | 'LG';
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  hasFocusBorder?: boolean;
  disableIconPosition?: boolean;
  customClassName?: string;
} & JSX.IntrinsicElements['input'] &
  ParentClassNameProp;

export const useStyles = makeStyles<InputProps>()({
  inputWrapper: (props) => [
    'relative',
    'rounded',
    'bg-white shadow-sm',
    'h-10',
    'text-sm',
    {
      'border border-primaryBorder focus-within:border-primaryBorderHover': props?.hasBorder,
      'border-2 border-primaryBorderActive': props?.hasFocusBorder,
      'px-3': props?.paddingX === 'SM',
      'px-8': props?.paddingX === 'LG',
      'w-full': !props?.disableFullWidth
    }
  ],
  iconContainer: (props?) => [
    'relative',
    {
      'w-full': !props?.disableFullWidth
    }
  ],
  prepend: (props) => [
    'shrink-0',
    'text-primaryIcon',
    {
      'absolute top-1/2': !props?.disableIconPosition,
      '-mt-2 ml-3': !props?.disableIconPosition
    }
  ],
  append: (props) => [
    'shrink-0',
    'text-primaryIcon',
    {
      'absolute top-1/2 right-0': !props?.disableIconPosition,
      '-mt-2 mr-3': !props?.disableIconPosition
    }
  ]
});

// TODO combine styles with TextInput
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disableFullWidth,
      disableIconPosition = false,
      className,
      paddingX = 'SM',
      prepend,
      append,
      disableStyle = false,
      hasBorder = true,
      hasFocusBorder = false,
      onChange = undefined,
      value = undefined,
      customClassName,
      ...props
    }: InputProps,
    ref
  ) => {
    const styles = useStyles({
      hasBorder,
      disableFullWidth,
      disableIconPosition,
      className,
      paddingX,
      hasFocusBorder
    });

    const inputElement = React.useMemo(
      () => (
        <input
          {...props}
          className={!disableStyle ? styles.inputWrapper : customClassName}
          onChange={onChange}
          value={value}
          ref={ref}
        />
      ),
      [ref, props]
    );
    const iconElement = (icon: React.ReactNode, { className }: ParentClassNameProp) =>
      React.useMemo(() => <div className={className}>{icon}</div>, [icon, className]);
    if (prepend || append) {
      return (
        <div className={styles.iconContainer}>
          {inputElement}
          {prepend && iconElement(prepend, { className: styles.prepend })}
          {append && iconElement(append, { className: styles.append })}
        </div>
      );
    }

    return inputElement;
  }
);

Input.displayName = 'Input';
