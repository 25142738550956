import { useEffect, useState } from 'react';

const useUserDevice = (): string => {
  const [device, setDevice] = useState<string>('iOS');

  useEffect(() => {
    if (window && window.navigator) {
      const { userAgent } = window.navigator;
      setDevice(() => {
        return (
          (userAgent.includes('Android') && 'Android') || (userAgent.includes('iPhone') && 'iOS')
        );
      });
    }
  }, []);

  return device;
};

export default useUserDevice;
