import { useTranslation } from 'hooks/useContentful/useTranslation';
import React from 'react';

import { TextInput, TextInputProps } from '../TextInput';

export type PriceInputProps = ParentClassNameProp & TextInputProps;

const PriceInput = React.forwardRef<HTMLInputElement, PriceInputProps>(
  (props: PriceInputProps, ref) => {
    const { t } = useTranslation();

    return (
      <TextInput
        className="w-28"
        ref={ref}
        prepend={t('CURRENCY_SYMBOL')}
        {...props}
        placeholder="0.00"
      />
    );
  }
);

PriceInput.displayName = 'PriceInput';

export { PriceInput };
