import { InputResetButton } from 'components/common/Button/InputResetButton';
import { SearchIcon } from 'icons/monochrome/SearchIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { InputBackButton } from '../../Button/InputBackButton';
import { Input, InputProps } from '../Input';

export type SearchInputProps = {
  placeholder?: string;
  customIcon?: React.ReactNode;
  hasBackButton?: boolean;
  handleBackButton?: GenericHandler;
  hasResetButton?: boolean;
  handleResetButton?: GenericHandler;
} & Pick<
  InputProps,
  'disableIconPosition' | 'onChange' | 'value' | 'onClickCapture' | 'onFocus' | 'onKeyDown'
> &
  ParentClassNameProp;

const useStyles = makeStyles()({
  root: 'relative w-full',
  // fix vertical offset
  searchIcon: ['mt-[1px]', 'text-sm text-secondaryIcon hover:text-secondaryIconActive'],
  backButton: [
    'absolute top-0 left-0',
    'bg-secondaryBackground hover:bg-secondaryBackground',
    'py-3 pb-[10px] px-1',
    'border border-primaryBorder rounded-l-lg',
    'shadow-sm'
  ],
  backButtonIcon: 'rotate-180'
});

const SearchInput = React.memo(
  ({
    placeholder,
    customIcon,
    hasBackButton = false,
    handleBackButton,
    hasResetButton = true,
    handleResetButton,
    onChange,
    value,
    ...props
  }: SearchInputProps) => {
    const styles = useStyles();
    return (
      <div className={styles.root}>
        <Input
          name="search"
          placeholder={placeholder}
          paddingX="LG"
          autoComplete="off"
          className="group"
          onChange={onChange}
          value={value}
          prepend={!hasBackButton && (customIcon ?? <SearchIcon className={styles.searchIcon} />)}
          {...props}
        />
        {hasBackButton && <InputBackButton handleClick={handleBackButton} />}
        {hasResetButton && (
          <InputResetButton show={Boolean(value)} handleClick={handleResetButton} />
        )}
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';

export { SearchInput };
