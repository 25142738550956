import cx from 'clsx';
import { HeroIcon } from 'components/common/HeroIcon';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { useCustomKeyDown } from 'hooks/useKeyboard';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import type { Props as NumberTextInputProps } from './NumberTextInput';

export type Props = {
  onMinus: (e) => void;
} & Pick<NumberTextInputProps, 'min'>;

export const useInputButtonStyles = makeStyles({
  iconButton: ['w-5 h-5', 'bg-primaryIcon hover:bg-primaryIconHover', 'cursor-pointer'],
  buttonContainer: [
    'bg-secondaryBackground hover:bg-secondaryBackgroundHover',
    'h-full',
    'flex items-center justify-center',
    'px-1',
    'border border-secondaryBorder hover:borderSecondaryHover'
  ],
  borderMinus: 'border-r rounded-l-md',
  borderPlus: 'border-l rounded-r-md'
});

const NumberInputButtonMinus = React.memo(({ onMinus }: Props) => {
  const styles = useInputButtonStyles();
  const { t } = useTranslation();

  const handleMinus = (e) => {
    e.preventDefault();
    return onMinus(e);
  };

  const handleCustomKeyDown = useCustomKeyDown({
    onEnter: handleMinus
  });

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleMinus}
      onKeyDown={handleCustomKeyDown}
      title={t('MINUS')}
      aria-label={t('MINUS')}
      className={cx(styles.buttonContainer, styles.borderMinus)}>
      <HeroIcon type="solid-mini" name="minus" className={styles.iconButton} />
    </div>
  );
});

NumberInputButtonMinus.displayName = 'NumberInputButtonMinus';

export { NumberInputButtonMinus };
