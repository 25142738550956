import { ChevronIcon } from 'icons/monochrome/ChevronIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type InputBackButtonProps = {
  handleClick?: GenericHandler;
};

const useStyles = makeStyles()({
  root: 'absolute top-0 left-0',
  backButton: [
    'bg-secondaryBackground hover:bg-secondaryBackgroundHover',
    'py-3 pb-[10px] px-1',
    'border border-primaryBorder rounded-l',
    'shadow-sm'
  ],
  backButtonIcon: 'rotate-180'
});

const InputBackButton = React.memo(({ handleClick }: InputBackButtonProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <button className={styles.backButton} onClick={handleClick}>
        <ChevronIcon className={styles.backButtonIcon} />
      </button>
    </div>
  );
});

InputBackButton.displayName = 'InputBackButton';

export { InputBackButton };
// TODO: add to Storybook
