import { useToggle } from 'hooks/useToggle';
import { makeStyles } from 'lib/makeStyles';
import React, { useCallback } from 'react';

import { NumberInputButtonMinus } from './NumberInputButtonMinus';
import { NumberInputButtonPlus } from './NumberInputButtonPlus';
import { NumberInputLabel } from './NumberInputLabel';
import { Props as NumberTextInputProps } from './NumberTextInput';

export type Props = {
  label?: string;
  value: number;
  step: number;
  isSmall?: boolean;
  fullWidth?: boolean;
} & Pick<NumberTextInputProps, 'onChange' | 'min' | 'max'>;

export const useStyles = makeStyles<Props>()({
  root: (props) => [
    'flex flex-row',
    'justify-center items-center content-center',
    {
      'w-min': !props.fullWidth
    },
    {
      grow: props.fullWidth
    },
    'bg-white',
    'border border-primaryBorder hover:border-primaryBorderHover',
    'filter drop-shadow-sm hover:drop-shadow',
    'rounded-md',
    {
      'h-10': !props.isSmall,
      'h-8': props.isSmall
    }
  ]
});

const NumberInputBox = React.memo((props: Props) => {
  const { disable: closeTextInput } = useToggle();
  const { value, step, onChange, label, fullWidth, min, max, isSmall } = props;
  const styles = useStyles(props);

  const onPlus = useCallback(
    (e) => {
      e.preventDefault();
      const newValue = value + step;
      if (typeof max === 'undefined' || newValue <= max) {
        closeTextInput(e);

        return onChange(newValue);
      }
    },
    [onChange, closeTextInput, value, step, max]
  );

  const onMinus = useCallback(
    (e) => {
      e.preventDefault();
      const newValue = value - step;
      if (typeof min === 'undefined' || newValue >= min) {
        closeTextInput(e);

        return onChange(newValue);
      }
    },
    [onChange, closeTextInput, value, step, min]
  );

  return (
    <div className={styles.root}>
      <NumberInputButtonMinus onMinus={onMinus} min={min} />
      <NumberInputLabel label={label} value={value} fullWidth={fullWidth} isSmall={isSmall} />
      <NumberInputButtonPlus onPlus={onPlus} max={max} />
    </div>
  );
});

NumberInputBox.displayName = 'NumberInputBox';

export { NumberInputBox };
