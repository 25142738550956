import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { NumberInputOwnProps } from './NumberInput';

export type Props = {
  label?: string;
  value: number;
  fullWidth?: boolean;
} & Pick<NumberInputOwnProps, 'fullWidth' | 'isSmall'>;

type StyleProps = Pick<Props, 'fullWidth' | 'isSmall'>;

export const useStyles = makeStyles<StyleProps>()({
  labelWrapper: (props) => [
    'flex',
    'py-1',
    'text-center',
    {
      'w-full': props.fullWidth,
      'px-6': !props.isSmall,
      'px-3': props.isSmall
    }
  ],
  valueWrapper: ['mx-auto'],
  value: ['text-secondaryText'],
  label: ['pl-2 text-neutralText']
});

const NumberInputLabel = React.memo(({ label, value, fullWidth, isSmall }: Props) => {
  const styles = useStyles({ fullWidth, isSmall });

  return (
    <div className={styles.labelWrapper}>
      <div className={styles.valueWrapper}>
        <span className={styles.value}>{value}</span>
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
});

NumberInputLabel.displayName = 'NumberInputLabel';

export { NumberInputLabel };
