import cx from 'clsx';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import MaskedInput from 'react-input-mask';

type OwnProps = {
  placeholder?: string;
  label?: string;
  isSmall?: boolean;
  fullWidth?: boolean;
  mask?: string;
  prepend?: string | React.ReactNode;
  append?: string | React.ReactNode;
  textarea?: boolean;
};

export type TextInputProps = OwnProps &
  React.ComponentPropsWithoutRef<'input'> &
  React.ComponentPropsWithoutRef<'textarea'>;

export const useStyles = makeStyles<TextInputProps>()({
  inputContainer: ['flex'],
  prepend: [
    'overflow-hidden',
    'rounded-l-md',
    'border-l border-t border-b border-primaryBorder',
    'bg-secondaryBackground',
    'pr-[1px]'
  ],
  append: [
    'overflow-hidden',
    'rounded-r-md',
    'border-r border-t border-b border-primaryBorder',
    'bg-secondaryBackground',
    'pl-[1px]'
  ],
  appendPrependContainer: [
    'flex justify-center items-center',
    'px-3 h-full',
    'cursor-default',
    'text-secondaryText'
  ],
  textInput: (props) => [
    'shadow-sm hover:shadow',
    'border border-primaryBorder hover:border-primaryBorderHover',
    'caret-gray-100',
    'placeholder:paragraph-600 placeholder:text-sm',
    'text-secondaryText',
    {
      'grow w-full min-w-0': props.fullWidth
    },
    {
      'px-3 h-10': !props.isSmall,
      'px-2 h-8': props.isSmall,
      'rounded-md': !props.prepend && !props.append,
      'border-l-0 rounded-r-md': props.prepend,
      'border-r-0 rounded-l-md': props.append
    },
    props.className
  ]
});

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  ({ isSmall, className, ...props }, ref) => {
    const {
      placeholder,
      label,
      id,
      name,
      disabled,
      mask,
      prepend,
      append,
      textarea = false,
      ...inputProps
    } = props;
    const styles = useStyles({ isSmall, className, prepend, append, ...props });
    const inputDefaultProps: TextInputProps = {
      type: !textarea ? 'text' : undefined,
      id,
      name,
      disabled,
      placeholder,
      className: styles.textInput,
      ...inputProps
    };
    const element = !textarea ? 'input' : 'textarea';
    const containerElement = label ? 'label' : 'div';

    return React.createElement(
      containerElement,
      { htmlFor: label ? id : undefined, className: cx(props.fullWidth && 'w-full') },
      [
        mask?.length ? (
          <MaskedInput {...inputDefaultProps} mask={mask}>
            {(maskProps) => <input {...maskProps} ref={ref} />}
          </MaskedInput>
        ) : (
          <div className={styles.inputContainer}>
            {prepend && (
              <div className={styles.prepend}>
                <div className={styles.appendPrependContainer}>{prepend}</div>
              </div>
            )}
            {React.createElement(element, { ...inputDefaultProps, ref })}
            {append && (
              <div className={styles.append}>
                <div className={styles.appendPrependContainer}>{append}</div>
              </div>
            )}
          </div>
        ),
        label && <span>{label}</span>
      ]
    );
  }
);

TextInput.defaultProps = {
  placeholder: 'Start typing',
  disabled: false
};

TextInput.displayName = 'TextInput';

export { TextInput };
