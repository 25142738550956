import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const SearchIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.805 13.2192C11.4533 12.8675 10.9 12.835 10.4682 13.0818C9.44579 13.6661 8.26189 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.26189 13.6661 9.44579 13.0818 10.4682C12.835 10.9 12.8675 11.4533 13.2192 11.805L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L11.805 13.2192ZM12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7Z"
      fill="currentColor"
    />
  </Glyph>
));

SearchIcon.displayName = 'SearchIcon';

export { SearchIcon };
