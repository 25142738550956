import cx from 'clsx';
import { HeroIcon } from 'components/common/HeroIcon';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { useCustomKeyDown } from 'hooks/useKeyboard';
import React from 'react';

import { useInputButtonStyles } from './NumberInputButtonMinus';
import type { Props as NumberTextInputProps } from './NumberTextInput';

export type Props = {
  onPlus: (e) => void;
} & Pick<NumberTextInputProps, 'max'>;

const NumberInputButtonPlus = React.memo(({ onPlus }: Props) => {
  const styles = useInputButtonStyles();
  const { t } = useTranslation();

  const handlePlus = (e) => {
    e.preventDefault();
    return onPlus(e);
  };

  const handleCustomKeyDown = useCustomKeyDown({
    onEnter: handlePlus
  });

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handlePlus}
      onKeyDown={handleCustomKeyDown}
      title={`${t('PLUS')}`}
      aria-label={`${t('PLUS')}`}
      className={cx(styles.buttonContainer, styles.borderPlus)}>
      <HeroIcon type="solid-mini" name="plus" className={styles.iconButton} />
    </div>
  );
});

NumberInputButtonPlus.displayName = 'NumberInputButtonPlus';

export { NumberInputButtonPlus };
