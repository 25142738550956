import { CloseCircleButton } from 'components/common/Button/CloseCircleButton';
import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type InputBackButtonProps = {
  handleClick?: GenericHandler;
  show?: boolean;
};

export const useStyles = makeStyles()({
  root: 'absolute top-3.5 right-2'
});

const InputResetButton = React.memo(({ handleClick, show }: InputBackButtonProps) => {
  const styles = useStyles();
  return (
    <OpacityTransition show={show}>
      <div className={styles.root}>
        <CloseCircleButton onClose={handleClick} />
      </div>
    </OpacityTransition>
  );
});

InputResetButton.displayName = 'InputResetButton';

export { InputResetButton };
// TODO: add to Storybook
